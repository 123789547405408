import { Button } from "components/button"
import { useLayout } from "contexts/components/LayoutContext"
import React from "react"
import { ImageBuilder } from "_types/AssetsImage"
import { BlogCard } from "../BlogCard/BlogCard"
import "./blog-content-block.module.scss"

interface BlogContentBlockProps {
  image?: ImageBuilder
  heading?: string
  description?: string
  slug: string
  date?: string
  blockPosition?: number
}

export const BlogContentBlock = ({
  date,
  slug,
  heading,
  image,
  description,
}: BlogContentBlockProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`

  return (
    <article className="fr-blog-content-block u-flex-center-align">
      <BlogCard
        heading={heading}
        slug={slug}
        description={description}
        image={image}
        date={date}
        hasDateTag
      />
      <div className="content-block__button-wrapper">
        <Button
          variant="outline-primary"
          to={path}
          style={{ marginTop: "auto" }}
          extraClassNames="content-block__button"
        >
          Read More
        </Button>
      </div>
    </article>
  )
}
