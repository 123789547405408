import React, { useCallback } from "react"
import { graphql, navigate, PageProps } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { GoTriangleRight } from "react-icons/go"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { usePaginationSlug } from "hooks/usePaginationSlug"
import { Breadcrumbs } from "components/breadcrumbs/Breadcrumbs"
import { BlogPageListContainer } from "components/blog/BlogListContainer/BlogPageListContainer"
import { BlogPosts } from "components/blog/BlogBanner/blog-banner-types"
import { BlogContentBlock } from "components/blog/BlogContentBlock/BlogContentBlock"
import { GenericHero } from "components/hero/GenericHero/GenericHero"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { Pagination } from "components/pagination/Pagination"
import { StubBanner } from "components/form-stub/StubBanner"
import "assets/scss/4-templates/blog-page-template.module.scss"
import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper"

type Articles = BlogPosts["edges"]

type PageContext = PageProps["pageContext"] & {
  currentPage: number
  numBlogPages: number
  category: string
  slug: string
  limit: number
  articles: Articles
  noIndex: boolean
  linkedPagesUrlPathCodex: Record<string, string>
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
}

interface BlogListProps extends PageProps {
  pageContext: PageContext
  data: any
}

const BlogList: React.FC<BlogListProps> = ({ location, pageContext, data }) => {
  const { pathname } = location

  const {
    currentPage,
    numBlogPages,
    category,
    slug,
    limit,
    articles,
    breadcrumb,
    noIndex,
    linkedPagesUrlPathCodex,
  } = pageContext

  const { homeSlug, pageSlug, prevPage, nextPage } = usePaginationSlug({
    slug,
    currentPage,
    isCategoryPath: !!category,
  })

  const onNextPage = useCallback(() => {
    navigate(nextPage)
  }, [nextPage])

  const onPreviousPage = useCallback(() => {
    navigate(prevPage)
  }, [prevPage])

  const onPageChange = useCallback(
    pageNumber => {
      navigate(pageNumber === 1 ? homeSlug : `${pageSlug}${pageNumber}`)
    },
    [homeSlug, pageSlug]
  )

  const [page] = data.allKontentItemPage.nodes
  const {
    banner,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    seo_metadata__rich_data_image,
    seo_metadata__overlay_cta_text,
    global_footer,
  } = page.elements

  let postArr = [...articles]

  if (category) {
    let filteredPosts: Articles = []
    if (category) {
      filteredPosts = postArr.filter(
        post =>
          post?.node?.elements?.category?.value[0]?.elements.category_name
            .value === category
      )
    }
    postArr = [...filteredPosts]
  }

  let currentPageCards = postArr.filter(
    (_, i) => i < currentPage * limit && i >= currentPage * limit - limit
  )

  const { globalFormStubBanner } = useGeneralSettings()

  return (
    <>
      <Layout
        location={slug}
        pathName={pathname}
        linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      >
        <SEO
          title={seo_metadata__meta_title.value}
          description={seo_metadata__meta_description.value}
          canonical={seo_metadata__canonical_link.value}
          noIndex={noIndex}
          image={seo_metadata__rich_data_image}
        />

        <GenericHero banner={banner.value[0].elements} />

        {globalFormStubBanner &&
          Object.keys(globalFormStubBanner).length > 0 && (
            <StubBanner formStub={globalFormStubBanner} />
          )}

        {category && (
          <Breadcrumbs
            crumbs={breadcrumb.crumbs.slice(0, 4)}
            crumbLabel={category}
            hiddenCrumbs={["/blog/category", "/page-*"]}
          />
        )}
        <BlocksWrapper>
          <section className="fr-blog-page-template u-content-block-padding u-flex-center-align">
            {category && (
              <div className="u-container-header fr-container fr-container--large">
                <h2 className={`fr-blog-page-template__heading`}>
                  <span className="fr-blog-page-template__heading--regular">
                    Articles Under
                  </span>{" "}
                  <GoTriangleRight className="fr-blog-page-template__heading--divider" />{" "}
                  <span>{category}</span>
                </h2>
              </div>
            )}

            <BlogPageListContainer>
              {currentPageCards.map(({ node }, i) => {
                const { id, elements } = node

                const {
                  featured_image,
                  featured_text,
                  heading,
                  published_date,
                  slug,
                } = elements

                let blockPosition = i + 1

                return (
                  <BlogContentBlock
                    key={`${id}-${i}`}
                    heading={heading?.value}
                    slug={slug?.value}
                    description={featured_text?.value}
                    image={featured_image?.value[0]}
                    date={published_date?.value}
                    blockPosition={blockPosition}
                  />
                )
              })}
            </BlogPageListContainer>

            {numBlogPages > 1 && (
              <div className="fr-container fr-container--large fr-blog-page-template__pagination-container">
                <Pagination
                  currentPage={currentPage}
                  totalCount={articles.length}
                  pageSize={limit}
                  onPageChange={onPageChange}
                  onPreviousPage={onPreviousPage}
                  onNextPage={onNextPage}
                />
              </div>
            )}
          </section>
        </BlocksWrapper>
        {global_footer && global_footer?.value?.length > 0 && (
          <GlobalFooter content={global_footer} />
        )}
      </Layout>
    </>
  )
}

export const getBlog = graphql`
  query getBlog($slug: String!) {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...HeroBannerFragment
            }
          }
          seo_metadata__overlay_cta_text {
            value
          }
          seo_metadata__canonical_link {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
          page_type {
            value {
              name
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          slug {
            value
          }
        }
      }
    }
  }
`
export default BlogList
